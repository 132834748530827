export const ARROW_DOWN = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9906 17.5953L19.293 11.2929L20.7072 12.7071L12.0001 21.4142L3.29297 12.7071L4.70718 11.2929L10.9906 17.5763L11.0001 2.99935L13.0001 3.00065L12.9906 17.5953Z"
    />
);
export const ARROW_LEFT = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40478 12.9905L12.7071 19.2929L11.2929 20.7071L2.58582 12L11.2929 3.29288L12.7071 4.70709L6.42369 10.9905L21.0006 11L20.9993 13L6.40478 12.9905Z"
    />
);
export const ARROW_RIGHT = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1817 12.9905L10.8793 19.2929L12.2935 20.7071L21.0006 12L12.2935 3.29291L10.8793 4.70712L17.1627 10.9905L2.58579 11L2.58709 13L17.1817 12.9905Z"
    />
);
export const ARROW_UP = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7166 6.81894L4.41421 13.1213L3 11.7071L11.7071 3L20.4142 11.7071L19 13.1213L12.7166 6.83791L12.7071 21.4148L10.7071 21.4135L10.7166 6.81894Z"
    />
);
export const CHECK = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7071 6.70708L8.20711 20.2071L1.79287 13.7928L3.20708 12.3786L8.20711 17.3786L20.2929 5.29287L21.7071 6.70708Z"
    />
);
export const CHEVRON_DOWN = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.5858L19.2929 7.29289L20.7072 8.70711L12 17.4142L3.29294 8.70711L4.70715 7.29289L12 14.5858Z"
    />
);
export const CHEVRON_LEFT = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41426 12L16.7072 19.2929L15.2929 20.7071L6.58583 12L15.2929 3.29289L16.7072 4.70711L9.41426 12Z"
    />
);
export const CHEVRON_RIGHT = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5857 12L7.29285 4.70711L8.70706 3.29289L17.4142 12L8.70706 20.7071L7.29285 19.2929L14.5857 12Z"
    />
);
export const CHEVRON_UP = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.41421L4.70706 16.7071L3.29285 15.2929L12 6.58579L20.7071 15.2929L19.2928 16.7071L12 9.41421Z"
    />
);
export const CLOSE = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4142 11.9999L20.7071 4.70709L19.2928 3.29288L12 10.5857L4.70715 3.29288L3.29294 4.70709L10.5858 11.9999L3.29285 19.2929L4.70706 20.7071L12 13.4142L19.2929 20.7071L20.7072 19.2929L13.4142 11.9999Z"
    />
);
export const TWITTER = (
    <path d="M19.995 6.68799C20.8915 6.15208 21.5622 5.30823 21.882 4.31399C21.0397 4.81379 20.1181 5.16587 19.157 5.35499C17.8246 3.94552 15.7135 3.60251 14.0034 4.51765C12.2933 5.43278 11.4075 7.37948 11.841 9.26999C8.39062 9.09676 5.17598 7.46691 2.99702 4.78599C1.85986 6.74741 2.44097 9.25477 4.32502 10.516C3.64373 10.4941 2.97754 10.3096 2.38202 9.97799C2.38202 9.99599 2.38202 10.014 2.38202 10.032C2.38241 12.0751 3.82239 13.8351 5.82502 14.24C5.19308 14.4119 4.53022 14.4372 3.88702 14.314C4.45022 16.0613 6.06057 17.2583 7.89602 17.294C6.37585 18.4871 4.49849 19.1342 2.56602 19.131C2.22349 19.1315 1.88123 19.1118 1.54102 19.072C3.50341 20.333 5.78739 21.0023 8.12002 21C11.3653 21.0223 14.484 19.7429 16.7787 17.448C19.0734 15.1531 20.3526 12.0342 20.33 8.78899C20.33 8.60299 20.3257 8.41799 20.317 8.23399C21.1575 7.62659 21.8828 6.87414 22.459 6.01199C21.676 6.35905 20.8455 6.58691 19.995 6.68799Z" />
);
export const WHATSAPP = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2.05 22l1.476-5.393c-.911-1.578-1.389-3.368-1.389-5.203C2.14 5.668 6.808 1 12.544 1a10.34 10.34 0 0 1 7.361 3.052c1.964 1.967 3.046 4.582 3.045 7.362-.003 5.737-4.671 10.406-10.406 10.406-1.741-.001-3.457-.437-4.977-1.267L2.05 22zm5.772-3.331c1.467.871 2.867 1.392 4.718 1.393a8.67 8.67 0 0 0 8.653-8.649 8.65 8.65 0 0 0-8.646-8.655c-4.77 0-8.651 3.88-8.653 8.649-.001 1.947.57 3.405 1.528 4.93l-.874 3.192 3.274-.858zm9.964-4.781c-.065-.108-.238-.173-.499-.304s-1.538-.76-1.777-.846-.411-.13-.585.13-.672.846-.823 1.019-.304.195-.563.065-1.098-.404-2.091-1.291c-.773-.69-1.295-1.541-1.446-1.802s-.016-.401.114-.53c.117-.116.26-.304.39-.456s.175-.259.262-.433.044-.325-.022-.456-.585-1.41-.801-1.93c-.212-.507-.426-.438-.585-.446L8.859 6.6c-.173 0-.455.065-.693.326s-.91.889-.91 2.169.932 2.516 1.061 2.69 1.833 2.8 4.442 3.926c.62.268 1.105.428 1.482.548.623.198 1.19.17 1.638.103.5-.074 1.538-.629 1.755-1.236s.217-1.129.151-1.237z"
    />
);
export const YOUTUBE = (
    <path d="M12.006 19.012H11.986C11.924 19.012 5.721 19 4.156 18.575C3.29543 18.3432 2.62335 17.6707 2.392 16.81C2.11058 15.2224 1.97469 13.6123 1.986 12C1.97873 10.385 2.11831 8.77271 2.403 7.183C2.64071 6.32151 3.30983 5.64595 4.169 5.4C5.691 5 11.723 5 11.979 5H12C12.063 5 18.282 5.012 19.831 5.437C20.6898 5.67001 21.3605 6.3411 21.593 7.2C21.8834 8.79354 22.0197 10.4113 22 12.031C22.007 13.644 21.8671 15.2543 21.582 16.842C21.3477 17.7016 20.6752 18.3726 19.815 18.605C18.295 19.008 12.262 19.012 12.006 19.012ZM10.006 9.005L10.001 15.005L15.213 12.005L10.006 9.005Z" />
);

export const MARKER = (
    <path
        xmlns="http://www.w3.org/2000/svg"
        d="M18.5 0.510345C16.0706 0.510334 13.6649 0.994122 11.4203 1.93409C9.17582 2.87406 7.13639 4.2518 5.41851 5.98864C3.70062 7.72549 2.33792 9.78742 1.40821 12.0567C0.478505 14.326 -1.12839e-05 16.7582 1.99543e-10 19.2145C1.99543e-10 29.5445 18.5 52.5103 18.5 52.5103C18.5 52.5103 37 29.5445 37 19.2145C37 16.7582 36.5215 14.326 35.5918 12.0567C34.6621 9.78742 33.2994 7.72549 31.5815 5.98864C29.8636 4.2518 27.8242 2.87406 25.5797 1.93409C23.3351 0.994122 20.9295 0.510334 18.5 0.510345ZM18.5 27.7672C16.7897 27.7672 15.1178 27.2545 13.6958 26.2938C12.2737 25.3331 11.1654 23.9677 10.5109 22.3702C9.85641 20.7727 9.68514 19.0148 10.0188 17.3188C10.3524 15.6229 11.176 14.0651 12.3854 12.8424C13.5947 11.6197 15.1355 10.787 16.8129 10.4496C18.4903 10.1123 20.229 10.2854 21.8091 10.9471C23.3892 11.6088 24.7397 12.7294 25.6899 14.1671C26.6401 15.6048 27.1473 17.2951 27.1474 19.0243C27.1473 21.343 26.2363 23.5668 24.6146 25.2064C22.9929 26.846 20.7934 27.7671 18.5 27.7672V27.7672Z"
        fill="#FFC700"
    />
);

export const INFORMATION = (
    <>
        <path
            d="M13.25 16.5v-6h-3V12h1.5v4.5H9.5V18h6v-1.5h-2.25ZM12.5 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
            fill="#003D80"
        />
        <path
            d="M12.5 22.5a10.5 10.5 0 1 1 0-21 10.5 10.5 0 0 1 0 21Zm0-19.5a9 9 0 1 0 0 18 9 9 0 0 0 0-18Z"
            fill="#003D80"
        />
    </>
);

export const WARNING = (
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3909 3.96395C19.0841 3.32506 18.603 2.78585 18.0031 2.40845C17.4033 2.03105 16.709 1.83081 16.0002 1.83081C15.2915 1.83081 14.5972 2.03105 13.9973 2.40845C13.3974 2.78585 12.9163 3.32506 12.6096 3.96395L1.78822 25.2506C0.493553 27.792 2.12955 31.1666 5.17755 31.1666H26.8216C29.8709 31.1666 31.5042 27.7933 30.2122 25.2506L19.3909 3.96395ZM16.0002 11.1666C16.3538 11.1666 16.693 11.3071 16.943 11.5571C17.1931 11.8072 17.3336 12.1463 17.3336 12.5V19.1666C17.3336 19.5202 17.1931 19.8594 16.943 20.1094C16.693 20.3595 16.3538 20.5 16.0002 20.5C15.6466 20.5 15.3075 20.3595 15.0574 20.1094C14.8074 19.8594 14.6669 19.5202 14.6669 19.1666V12.5C14.6669 12.1463 14.8074 11.8072 15.0574 11.5571C15.3075 11.3071 15.6466 11.1666 16.0002 11.1666ZM16.0002 22.5C16.3538 22.5 16.693 22.6404 16.943 22.8905C17.1931 23.1405 17.3336 23.4797 17.3336 23.8333V24.5C17.3336 24.8536 17.1931 25.1927 16.943 25.4428C16.693 25.6928 16.3538 25.8333 16.0002 25.8333C15.6466 25.8333 15.3075 25.6928 15.0574 25.4428C14.8074 25.1927 14.6669 24.8536 14.6669 24.5V23.8333C14.6669 23.4797 14.8074 23.1405 15.0574 22.8905C15.3075 22.6404 15.6466 22.5 16.0002 22.5Z"
            fill="#F86F0B"
        />
    </>
);
