import { BaseHead } from "components";

import { GlobalStyle } from "config";

import type { AppProps } from "next/app";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    return (
        <>
            <GlobalStyle />
            <BaseHead />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <Component {...pageProps} />
        </>
    );
}
export default MyApp;
