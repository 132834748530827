import { ReactNode, useRef, useState } from "react";

import {
    StyledTooltip,
    ToolTipContent,
    TooltipButton
} from "components/shared/tooltip/Tooltip.styled";

import { useMediaQueryContext, useOnClickOutside } from "helpers/hooks";

export interface TooltipProps {
    children: ReactNode;
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Set tooltip text for a tooltip on the right side of the label */
    tooltipText: string;
}

export function Tooltip({
    children,
    className,
    tooltipText
}: TooltipProps): JSX.Element {
    const [isToolTipOpen, setIsTooltipOpen] = useState(false);
    const { isDesktopView } = useMediaQueryContext();
    const showTooltip = isDesktopView || isToolTipOpen;
    const tooltipRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(tooltipRef.current, () => setIsTooltipOpen(false));

    return (
        <StyledTooltip className={className} ref={tooltipRef}>
            <TooltipButton
                aria-label="toggle-tooltip"
                onClick={() => setIsTooltipOpen(!isToolTipOpen)}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
            >
                {children}
            </TooltipButton>
            {showTooltip && <ToolTipContent>{tooltipText}</ToolTipContent>}
        </StyledTooltip>
    );
}
