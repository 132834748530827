import { createGlobalStyle } from "styled-components";

import { media } from "constants/media";

export const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: BerninaSans-Regular;
        font-weight: 400;
        src: url("https://storage.googleapis.com/gracious-email/ANWB/BerninaSans.ttf");
        src: url("https://storage.googleapis.com/gracious-email/ANWB/BerninaSans.ttf?#iefix") format("TrueType");
    }

    @font-face {
        font-family: BerninaSans-Bold;
        font-weight: 400;
        src: url("https://storage.googleapis.com/gracious-email/ANWB/BerninaSans-Bold.ttf");
        src: url("https://storage.googleapis.com/gracious-email/ANWB/BerninaSans-Bold.ttf?#iefix") format("TrueType");
    }

    @font-face {
        font-family: BerninaSans-SemiBold;
        font-weight: 400;
        src: url("https://storage.googleapis.com/gracious-email/ANWB/BerninoSans-Office-Semibold.ttf");
        src: url("https://storage.googleapis.com/gracious-email/ANWB/BerninoSans-Office-Semibold.ttf?#iefix") format("TrueType");
    }

    html {
        /* ===== Theme colors ====== */
        --color-primaryLight: #c6ecff;
        --color-primary: #25a3dd;
        --color-primaryDark: #003d80;

        --color-secondaryLight: #4b5983;
        --color-secondary: #1e3064;
        --color-secondaryDark: #182650;

        /* --color-errorLight:
        --color-error:
        --color-errorDark:

        --color-warningLight:
        --color-warning:
        --color-warningDark:

        --color-successLight:
        --color-success:
        --color-successDark:

        --color-dimmedLight:
        --color-dimmed:
        --color-dimmedDark: */

        --color-white: #fff;
        --color-black: #000;

        /* Support colors */

        --color-red-40: #e24242;
        --color-red-45: #e23535;
        --color-red-50: #d73232;
        --color-red-55: #d22424;
        --color-red-60: #c51f1f;

        --color-green-40: #59e899;
        --color-green-45: #3ae487;
        --color-green-50: #2ce27e;
        --color-green-55: #1ed771;
        --color-green-60: #1ac165;

        --color-orange-40: #ffa25e;
        --color-orange-45: #ff914b;
        --color-orange-50: #ff8642;
        --color-orange-55: #fa7c3d;
        --color-orange-60: #eb6e37;

        --color-blue-40: #7fd7eb;
        --color-blue-45: #5acce5;
        --color-blue-50: #48c6e2;
        --color-blue-55: #38b9da;
        --color-blue-60: #2cadd3;

        --color-red-light: #f5e4e4;
        --color-green-light: #b2ffc6;

        --color-grey: #b2b2bf;
        --color-light-grey: #f8f8ff;
        --color-border-grey: #b3b3b3;

        --main-navigation-height: 104px;

        /* Footer */

        --footer-max-width: 1216px;

        /* Button */

        --type-button-line-height: 24px;
        --type-button-font-size-small: 13px;
        --type-button-font-size-large: 15px;

        --button-primary-box-shadow: 0 8px 12px rgba(128, 0, 255, 0.17);
        --button-secondary-box-shadow: 0 8px 12px rgba(44, 226, 126, 0.19);

        /* Animation */

        --animation-small-duration: 100ms;
        --animation-medium-duration: 200ms;
        --animation-medium-entry-duration: 250ms;
        --animation-medium-exit-duration: 200ms;
        --animation-large-entry-duration: 300ms;
        --animation-large-exit-duration: 250ms;
        --animation-long-duration: 1.5s;
        --animation-extra-long-duration: 3s;

        /* Dropdown */

        --dropdown-box-shadow: 0 76px 80px rgba(34, 12, 74, 0.07),
            0 27.7413px 29.2013px rgba(34, 12, 74, 0.05),
            0 13.4679px 14.1767px rgba(34, 12, 74, 0.04),
            0 6.6022px 6.94968px rgba(34, 12, 74, 0.03),
            0 2.61052px 2.74791px rgba(34, 12, 74, 0.02);

        --pill-box-shadow: 0 2px 31px rgba(34, 12, 74, 0.07),
            0 10.5083px 15.6991px rgba(34, 12, 74, 0.06151,)
            0 5.2525px 7.58201px rgba(34, 12, 74, 0.0528532),
            0 2.47219px 3.48928px rgba(34, 12, 74, 0.0430861),
            0 0.886661px 1.24172px rgba(34, 12, 74, 0.0298631);

        /* TextButton */

        --type-anchor-font-size-small: 13px;
        --type-anchor-line-height-small: 24px;

        --type-anchor-font-size-large: 15px;
        --type-anchor-line-height-large: 32px;

        --button-color-disabled: #b2b2bf;

        /* Static page menu */
        --color-menu-item-hover: rgba(128, 0, 255, 0.05);

        /* Milestone */
        --color-milestone-dot: rgba(44, 226, 126, 0.1);

        /* Transition duration variable */
        --quick-transition-duration: 150ms;
        --default-transition-duration: 250ms;

        /* Typography */

        --font-primary: "BerninaSans-Regular";
        --font-primary-semibold: "BerninaSans-SemiBold";
        --font-primary-bold: "BerninaSans-Bold";

        --type-heading-1-font-size: 32px;
        --type-heading-1-line-height: 48px;
        --type-heading-2-font-size: 30px;
        --type-heading-2-line-height: 40px;
        --type-heading-3-font-size: 25px;
        --type-heading-3-line-height: 40px;
        --type-heading-4-font-size: 24px;
        --type-heading-4-line-height: 32px;
        --type-heading-5-font-size: 16px;
        --type-heading-5-line-height: 28px;
        --type-heading-6-font-size: 14px;
        --type-heading-6-line-height: 24px;

        --type-paragraph-font-size: 13px;
        --type-paragraph-line-height: 24px;

        --type-label-font-size: 14px;
        --type-label-line-height: 24px;

        --type-sub-text-font-size-sub-text: 12px;
        --type-sub-text-line-height: 24px;

        /* Range input */

        --range-input-label-font: ClanPro-Wide, serif;
        --range-input-value-counter-font: Alterra Display, serif;

        /* Whitepaper */

        --whitepaper-image-drop-shadow: drop-shadow(0 22px 55px rgba(0, 0, 0, 0.27)) drop-shadow(0 6.63236px 16.5809px rgba(0, 0, 0, 0.175929)) drop-shadow(0 2.75474px 6.88685px rgba(0, 0, 0, 0.135)) drop-shadow(0 0.996336px 2.49084px rgba(0, 0, 0, 0.094071));
        --whitepaper-download-block-box-shadow: 0 76px 80px rgba(34, 12, 74, 0.07), 0 27.7413px 29.2013px rgba(34, 12, 74, 0.05), 0 13.4679px 14.1767px rgba(34, 12, 74, 0.04), 0 6.6022px 6.94968px rgba(34, 12, 74, 0.03), 0 2.61052px 2.74791px rgba(34, 12, 74, 0.02);

        /* Modal Overlay */
        --overlay-color: #b2b2bf;

        /* Smooth scroll behavior for scrolling to ID'ed elements on static pages */

        background: #c6ecff;
        scroll-behavior: smooth;

        ${media.mdUp} {
            --type-heading-1-font-size: 55px;
            --type-heading-1-line-height: 64px;
            --type-heading-2-font-size: 45px;
            --type-heading-2-line-height: 64px;
            --type-heading-3-font-size: 40px;
            --type-heading-3-line-height: 56px;
            --type-heading-4-font-size: 35px;
            --type-heading-4-line-height: 48px;
            --type-heading-5-font-size: 20px;
            --type-heading-5-line-height: 32px;
            --type-heading-6-font-size: 15px;
            --type-heading-6-line-height: 32px;

            --type-paragraph-font-size: 15px;
            --type-paragraph-line-height: 32px;

            --type-sub-text-font-size-sub-text: 14px;
        }
    }

    * {
        box-sizing: border-box;
    }

    /* Headers */

    h1 {
        font-size: var(--type-heading-1-font-size);
        line-height: var(--type-heading-1-line-height);
    }

    h2 {
        font-size: var(--type-heading-2-font-size);
        line-height: var(--type-heading-2-line-height);
    }

    h3 {
        font-size: var(--type-heading-3-font-size);
        line-height: var(--type-heading-3-line-height);
    }

    h4 {
        font-size: var(--type-heading-4-font-size);
        line-height: var(--type-heading-4-line-height);
    }

    h5 {
        font-size: var(--type-heading-5-font-size);
        line-height: var(--type-heading-5-line-height);
    }

    h6 {
        font-size: var(--type-heading-6-font-size);
        line-height: var(--type-heading-6-line-height);
        text-transform: uppercase;
    }

    p {
        font-size: var(--type-paragraph-font-size);
        line-height: var(--type-paragraph-line-height);
    }


    /* General components */

    a {
        color: var(--color-violet-50);
        font-size: var(--type-anchor-font-size);
        line-height: var(--type-anchor-line-height);
    }

    button {
        font-size: var(--type-button-font-size-small);
        line-height: var(--type-button-line-height);
    }

    label {
        font-size: var(--type-label-font-size);
        line-height: var(--type-label-line-height);
    }

    /*  General styling */

    html,
    body,
    button,
    input,
    select,
    textarea {
        font-family: var(--font-primary), sans-serif;
    }

    input,
    textarea {
        font-size: var(--font-size---type-paragraph-font-size);
    }

    input {
        background-color: var(--color-light-grey);
        border: solid 1px var(--color-primaryLight);
        border-radius: 4px;
        color: var(--color-primary);
        line-height: 32px;
        padding: 11px 16px;
        transition-duration: var(--default-transition-duration);
        transition-property: box-shadow, border-color, background-color, color;
        transition-timing-function: ease;
        width: 100%;
    }

    input::placeholder {
        color: #003d80;
    }


    html,
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin: 0;
        padding: 0;
    }

    /* stylelint-disable selector-max-id */
    #__next {
        overflow-x: hidden;
    }

    /* stylelint-enable selector-max-id */

    .react-modal {
        background: transparent;
        border-radius: 4px;
        box-shadow: var(--dropdown-box-shadow);
        left: 50%;
        max-height: 100%;
        opacity: 0;
        outline: none;
        overflow: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.4s opacity ease-in-out;
        z-index: 14;

        svg g {
            fill: var(--color-secondary);
        }

        &-overlay {
            background: var(--overlay-color);
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 13;
        }

    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ReactModal__Overlay {
        &--after-open .react-modal {
            opacity: 1;
        }

        &--before-close .react-modal {
            opacity: 0;
        }
    }

    /* stylelint-disable */
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        border-color: #ccc;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        height: 9px;
        position: absolute;
        top: 6px;
        width: 9px;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        margin-left: -4px;
        position: absolute;
        width: 0;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        box-sizing: content-box;
        position: absolute;
        border: 8px solid transparent;
        height: 0;
        width: 1px;
        content: "";
        z-index: 0;
        border-width: 8px;
        left: -8px;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
        border-bottom-color: #aeaeae;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        top: 0;
        margin-top: -8px;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-top: none;
        border-bottom-color: #f0f0f0;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        top: 0;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
        top: -1px;
        border-bottom-color: #aeaeae;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
        bottom: 0;
        margin-bottom: -8px;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        border-bottom: none;
        border-top-color: #fff;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        bottom: 0;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
        bottom: -1px;
        border-top-color: #aeaeae;
    }

    .react-datepicker-wrapper {
        display: inline-block;
        padding: 0;
        border: 0;
        width: 100%;
    }

    .react-datepicker {
        background-color: #fff;
        border-color: transparent;
        border-radius: 16px;
        box-shadow: 0px 12px 33px rgba(0, 0, 0, 0.1);
        color: #003D80;
        display: inline-block;
        font-family: var(--font-primary), helvetica, arial, sans-serif;
        font-size: 16px;
        position: relative;
    }

    .react-datepicker--time-only .react-datepicker__triangle {
        left: 35px;
    }

    .react-datepicker--time-only .react-datepicker__time-container {
        border-left: 0;
    }

    .react-datepicker--time-only .react-datepicker__time,
    .react-datepicker--time-only .react-datepicker__time-box {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker-popper {
        z-index: 1;
    }

    .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 24px;
    }

    .react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
        left: auto;
        right: 50px;
    }

    .react-datepicker-popper[data-placement^=top] {
        padding-bottom: 60px;
    }

    .react-datepicker-popper[data-placement^=right] {
        padding-left: 8px;
    }

    .react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
        left: auto;
        right: 42px;
    }

    .react-datepicker-popper[data-placement^=left] {
        padding-right: 8px;
    }

    .react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
        left: 42px;
        right: auto;
    }

    .react-datepicker__header {
        text-align: center;
        background-color: #f0f0f0;
        border-bottom: transparent;
        border-top-left-radius: 0.3rem;
        padding: 8px 0;
        position: relative;
    }

    .react-datepicker__header--time {
        padding-bottom: 8px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
        border-top-left-radius: 0;
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        background: white;
        border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
        display: inline-block;
        margin: 0 2px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: #003D80;
        font-size: 0.944rem;
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        margin-top: 0;
    }

    .react-datepicker-time__header {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .react-datepicker__navigation {
        align-items: center;
        background: none;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 2px;
        padding: 0;
        border: none;
        z-index: 1;
        height: 32px;
        width: 32px;
        text-indent: -999em;
        overflow: hidden;
    }

    .react-datepicker__navigation--previous {
        left: 2px;
    }

    .react-datepicker__navigation--next {
        right: 2px;
    }

    .react-datepicker__navigation--previous,
     .react-datepicker__navigation--next {
         top: 8px;
     }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 85px;
    }

    .react-datepicker__navigation--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .react-datepicker__navigation--years-previous {
        top: 4px;
    }

    .react-datepicker__navigation--years-upcoming {
        top: -4px;
    }

    .react-datepicker__navigation:hover *::before {
        border-color: #a6a6a6;
    }

    .react-datepicker__navigation-icon {
        position: relative;
        top: -1px;
        font-size: 20px;
        width: 0;
    }

    .react-datepicker__navigation-icon--next {
        left: -2px;
    }

    .react-datepicker__navigation-icon--next::before {
        transform: rotate(45deg);
        left: -7px;
    }

    .react-datepicker__navigation-icon--previous {
        right: -2px;
    }

    .react-datepicker__navigation-icon--previous::before {
        transform: rotate(225deg);
        right: -7px;
    }

    .react-datepicker__month-container {
        float: left;
    }

    .react-datepicker__year {
        margin: 0.4rem;
        text-align: center;
    }

    .react-datepicker__year-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 180px;
    }

    .react-datepicker__year .react-datepicker__year-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }

    .react-datepicker__month {
        margin: 0.4rem;
        text-align: center;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }

    .react-datepicker__input-time-container {
        clear: both;
        width: 100%;
        float: left;
        margin: 5px 0 10px 15px;
        text-align: left;
    }

    .react-datepicker__input-time-container .react-datepicker-time__caption {
        display: inline-block;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container {
        display: inline-block;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
        display: inline-block;
        margin-left: 10px;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: auto;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
        -moz-appearance: textfield;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
        margin-left: 5px;
        display: inline-block;
    }

    .react-datepicker__time-container {
        float: right;
        border-left: 1px solid #aeaeae;
        width: 85px;
    }

    .react-datepicker__time-container--with-today-button {
        display: inline;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        position: absolute;
        right: -72px;
        top: 0;
    }

    .react-datepicker__time-container .react-datepicker__time {
        position: relative;
        background: white;
        border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 85px;
        overflow-x: hidden;
        margin: 0 auto;
        text-align: center;
        border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        height: 30px;
        padding: 5px 10px;
        white-space: nowrap;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
        cursor: pointer;
        background-color: #f0f0f0;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: #25a3dd;
        color: white;
        font-weight: bold;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
        background-color: #25a3dd;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        color: #ccc;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
        cursor: default;
        background-color: transparent;
    }

    .react-datepicker__week-number {
        color: #ccc;
        display: inline-block;
        width: 1.7rem;
        line-height: 1.7rem;
        text-align: center;
        margin: 0.166rem;
    }

    .react-datepicker__week-number.react-datepicker__week-number--clickable {
        cursor: pointer;
    }

    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
        border-radius: 50%;
        background-color: #f0f0f0;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        white-space: nowrap;
    }

    .react-datepicker__day-names {
        font-size: 10px;
        font-weight: 600;
        margin-bottom: -24px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: #003D80;
        display: inline-block;
        width: 1.7rem;
        line-height: 1.7rem;
        text-align: center;
        margin: 0.166rem;
    }

       .react-datepicker__day-name {
           color: #c7c7c7;
       }


    .react-datepicker__month--selected,
    .react-datepicker__month--in-selecting-range,
    .react-datepicker__month--in-range,
    .react-datepicker__quarter--selected,
    .react-datepicker__quarter--in-selecting-range,
    .react-datepicker__quarter--in-range {
        border-radius: 50%;
        background-color: #83D4FE;
        color: #fff;
    }

    .react-datepicker__month--selected:hover,
    .react-datepicker__month--in-selecting-range:hover,
    .react-datepicker__month--in-range:hover,
    .react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter--in-selecting-range:hover,
    .react-datepicker__quarter--in-range:hover {
        background-color: #1d5d90;
    }

    .react-datepicker__month--disabled,
    .react-datepicker__quarter--disabled {
        color: #ccc;
        pointer-events: none;
    }

    .react-datepicker__month--disabled:hover,
    .react-datepicker__quarter--disabled:hover {
        cursor: default;
        background-color: transparent;
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        cursor: pointer;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 50%;
        background-color: #f0f0f0;
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
        font-weight: bold;
    }

    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
        border-radius: 0.3rem;
        background-color: #3dcc4a;
        color: #fff;
    }

    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
        background-color: #32be3f;
    }

    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
        color: magenta;
    }

    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
        color: green;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 50%;
        background-color: #003D80;
        color: #fff;
    }

    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
        background-color: #1d5d90;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        border-radius: 50%;
        background-color: transparent;
        color: #003D80;
    }

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
        background-color: transparent;
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range),
    .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range),
    .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range),
    .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range) {
        background-color: rgba(33, 107, 165, 0.5);
    }

    .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range) {
        background-color: #f0f0f0;
        color: #003D80;
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }

    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
        background-color: transparent;
    }

    .react-datepicker__month-text.react-datepicker__month--selected:hover,
    .react-datepicker__month-text.react-datepicker__month--in-range:hover,
    .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
        background-color: #25a3dd;
    }

    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover {
        background-color: #f0f0f0;
    }

    .react-datepicker__input-container {
        display: inline-block;
        position: relative;
        width: 100%;

        input {
             cursor: pointer;
        }
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
        border: 1px solid transparent;
        border-radius: 0.3rem;
        position: relative;
    }

    .react-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover,
    .react-datepicker__month-year-read-view:hover {
        cursor: pointer;
    }

    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
        border-top-color: #b3b3b3;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        transform: rotate(135deg);
        right: -16px;
        top: 0;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        background-color: #f0f0f0;
        position: absolute;
        width: 50%;
        left: 25%;
        top: 30px;
        z-index: 1;
        text-align: center;
        border-radius: 0.3rem;
        border: 1px solid #aeaeae;
    }

    .react-datepicker__year-dropdown:hover,
    .react-datepicker__month-dropdown:hover,
    .react-datepicker__month-year-dropdown:hover {
        cursor: pointer;
    }

    .react-datepicker__year-dropdown--scrollable,
    .react-datepicker__month-dropdown--scrollable,
    .react-datepicker__month-year-dropdown--scrollable {
        height: 150px;
        overflow-y: scroll;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
        line-height: 20px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__month-option:first-of-type,
    .react-datepicker__month-year-option:first-of-type {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:last-of-type,
    .react-datepicker__month-option:last-of-type,
    .react-datepicker__month-year-option:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
        background-color: #ccc;
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
        border-bottom-color: #b3b3b3;
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
        border-top-color: #b3b3b3;
    }

    .react-datepicker__year-option--selected,
    .react-datepicker__month-option--selected,
    .react-datepicker__month-year-option--selected {
        position: absolute;
        left: 15px;
    }

    .react-datepicker__close-icon {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 0 6px 0 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
    }

    .react-datepicker__close-icon::after {
        cursor: pointer;
        background-color: #25a3dd;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 2px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "×";
    }

    .react-datepicker__today-button {
        background: #f0f0f0;
        border-top: 1px solid #aeaeae;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        padding: 5px 0;
        clear: left;
    }

    .react-datepicker__portal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 2147483647;
    }

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    @media (max-width: 400px), (max-height: 550px) {
        .react-datepicker__portal .react-datepicker__day-name,
        .react-datepicker__portal .react-datepicker__day,
        .react-datepicker__portal .react-datepicker__time-name {
            width: 2rem;
            line-height: 2rem;
        }
    }

    .react-datepicker__portal .react-datepicker__current-month,
    .react-datepicker__portal .react-datepicker-time__header {
        font-size: 1.44rem;
    }
    /* stylelint-enable */

`;
